import {useMemo} from "react";
import {CurrencyAmount, Price, TradeType} from "@uniswap/sdk-core";
import {useClientSideV3Trade} from "./useClientSideV3Trade";
import {USDC} from "../config/tokens";

export const STABLECOIN_AMOUNT_OUT = {
    137: CurrencyAmount.fromRawAmount(USDC, 10_000e6),
    80001: CurrencyAmount.fromRawAmount(USDC, 10_000e6),
}

export default function useUSDCPrice(currency) {
    const chainId = currency?.chainId

    const amountOut = chainId ? STABLECOIN_AMOUNT_OUT[chainId] : undefined
    const stablecoin = amountOut?.currency

    const v3USDCTrade = useClientSideV3Trade(TradeType.EXACT_OUTPUT, amountOut, currency)

    return useMemo(() => {
        if (!currency || !stablecoin) {
            return undefined
        }

        // handle usdc
        if (currency?.wrapped.equals(stablecoin)) {
            return new Price(stablecoin, stablecoin, '1', '1')
        }
        if (v3USDCTrade.trade) {
            const { numerator, denominator } = v3USDCTrade.trade.routes[0].midPrice
            return new Price(currency, stablecoin, denominator, numerator)
        }

        return undefined
    }, [currency, stablecoin, v3USDCTrade.trade])
}

export function useUSDCValue(currencyAmount) {
    const price = useUSDCPrice(currencyAmount?.currency)

    return useMemo(() => {
        if (!price || !currencyAmount) return null
        try {
            return price.quote(currencyAmount)
        } catch (error) {
            return null
        }
    }, [currencyAmount, price])
}