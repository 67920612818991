import { CurrencyAmount } from '@uniswap/sdk-core'
import JSBI from 'jsbi'

const MIN_NATIVE_CURRENCY_FOR_GAS: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH

export function maxAmountSpend(currencyAmount) {
    if (!currencyAmount || !(currencyAmount instanceof CurrencyAmount)) return undefined
    if (currencyAmount.currency.isNative) {
        if (JSBI.greaterThan(currencyAmount.quotient, MIN_NATIVE_CURRENCY_FOR_GAS)) {
            return CurrencyAmount.fromRawAmount(
                currencyAmount.currency,
                JSBI.subtract(currencyAmount.quotient, MIN_NATIVE_CURRENCY_FOR_GAS)
            )
        } else {
            return CurrencyAmount.fromRawAmount(currencyAmount.currency, JSBI.BigInt(0))
        }
    }
    return currencyAmount
}
