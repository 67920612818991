import { useEffect, useMemo, useState } from "react"
import { useBestTrade } from "./useBestTrade"
import { JSBI } from "@uniswap/sdk"
import { OCToken, wMATIC } from "../config/tokens"
import { CurrencyAmount, TradeType } from "@uniswap/sdk-core"
import { useCurrency } from "./Tokens"
import tryParseCurrencyAmount from "../utils/tryParseCurrencyAmount"
import { useMaticPrice } from "./usePrice"
// import useActiveWeb3React from "./useWeb3"

export const useOcPrice = () => {
    // const { library, chainId } = useActiveWeb3React()
    const [ price, setPrice ] = useState()
    const maticPrice = useMaticPrice()

    const ocToken = useCurrency(OCToken.address)
    const matic = useCurrency(wMATIC.address)

    const parsedAmount = useMemo(
        () => tryParseCurrencyAmount('1', ocToken ?? undefined),
        [ ]
    )

    const trade = useBestTrade(
        TradeType.EXACT_INPUT,
        parsedAmount,
        matic ?? undefined
    )

    useEffect(() => {
        if ( trade.state === 3 ) {
            const price = trade.trade?.executionPrice?.toSignificant(6)
            console.log(price)
            setPrice(maticPrice * price)
        }
    }, [trade, maticPrice])

    return useMemo(() => price, [price])
}