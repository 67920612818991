// import {Token} from "../config/tokens";
// import {useTokenContract} from "./useContract";
// import {useCallback, useEffect, useMemo, useState} from "react";
import { useMemo } from "react";
// import {formatEther, formatUnits} from "@ethersproject/units";

import { Interface } from '@ethersproject/abi'
import ERC20ABI from '../config/abis/tokenABI.json'
import JSBI from 'jsbi'
import { useMultipleContractSingleData, useSingleContractMultipleData } from './multicall'

import { isAddress } from '../utils'
import useActiveWeb3React from "./useWeb3";
import {useInterfaceMulticall} from "./useContract";
import {CurrencyAmount} from "@uniswap/sdk-core";
import {MaticNativeCurrency} from "../config/tokens";

// export const useTokenBalance = (token: Token) => {
//     const { account, library } = useActiveWeb3React()
//     const tokenInstance = useTokenContract(token.address)
//     const [ tokenBalance, setTokenBalance ] = useState(0)
//
//     const fetchBalance = useCallback(async ( ) => {
//         if ( token.isNative ) {
//             const provider = library
//             const nativeBalance = await provider.getBalance(account)
//             const balanceFormatted = formatEther(nativeBalance)
//             setTokenBalance(balanceFormatted)
//         } else {
//             const decimals = await tokenInstance.decimals()
//             const balanceParsed = await tokenInstance.balanceOf( account )
//             const balanceFormatted = formatUnits( balanceParsed, decimals )
//
//             setTokenBalance(balanceFormatted)
//         }
//     }, [tokenInstance, account, setTokenBalance, token, library])
//
//     useEffect(() => {
//         if ( account && library ) {
//             fetchBalance().then(r => console.debug('balance fetched'))
//         }
//     }, [account, tokenInstance, fetchBalance, library])
//
//
//     return useMemo(() => tokenBalance, [tokenBalance])
// }

export function useNativeCurrencyBalances(uncheckedAddresses) {
    const { chainId } = useActiveWeb3React()
    const multicallContract = useInterfaceMulticall()

    const validAddressInputs = useMemo(
        () =>
            uncheckedAddresses
                ? uncheckedAddresses
                    .map(isAddress)
                    .filter((a) => a !== false).sort()
        .map((addr) => [addr])
        : [],
        [uncheckedAddresses]
    )

    const results = useSingleContractMultipleData(multicallContract,'getEthBalance',validAddressInputs)

    return useMemo(
        () =>
            validAddressInputs.reduce((memo, [address], i) => {
                const value = results?.[i]?.result?.[0]
                if (value && chainId)
                    memo[address] = CurrencyAmount.fromRawAmount(new MaticNativeCurrency(chainId), JSBI.BigInt(value.toString()))
                return memo
            }, {}),
        [validAddressInputs, chainId, results]
    )
}

const ERC20Interface = new Interface(ERC20ABI)
const tokenBalancesGasRequirement = { gasRequired: 185_000 }

/**
 * Returns a map of token addresses to their eventually consistent token balances for a single account.
 */
export function useTokenBalancesWithLoadingIndicator(
    address,
    tokens
) {
    const { chainId } = useActiveWeb3React() // we cannot fetch balances cross-chain
    const validatedTokens = useMemo(
        () => tokens?.filter((t) => isAddress(t?.address) !== false && t?.chainId === chainId) ?? [],
        [chainId, tokens]
)
    const validatedTokenAddresses = useMemo(() => validatedTokens.map((vt) => vt.address), [validatedTokens])

    const balances = useMultipleContractSingleData(
        validatedTokenAddresses,
        ERC20Interface,
        'balanceOf',
        useMemo(() => [address], [address]),
        tokenBalancesGasRequirement
    )

    const anyLoading = useMemo(() => balances.some((callState) => callState.loading), [balances])
    return useMemo(
        () => [
            address && validatedTokens.length > 0
                ? validatedTokens.reduce((memo, token, i) => {
                    const value = balances?.[i]?.result?.[0]
                    const amount = value ? JSBI.BigInt(value.toString()) : undefined
                    if (amount) {
                        memo[token.address] = CurrencyAmount.fromRawAmount(token, amount)
                    }
                    return memo
                }, {})
                : {},
            anyLoading,
        ],
        [address, validatedTokens, anyLoading, balances]
    )
}

export function useTokenBalances(
    address,
    tokens
) {
    return useTokenBalancesWithLoadingIndicator(address, tokens)[0]
}

// get the balance for a single token/account combo
export function useTokenBalance(token) {
    const { account } = useActiveWeb3React()
    const tokenBalances = useTokenBalances(
        account,
        useMemo(() => [token], [token])
    )
    if (!token) return undefined
    return tokenBalances[token.address]
}

export function useCurrencyBalances(
    account,
    currencies
) {
    const tokens = useMemo(
        () => currencies?.filter((currency) => isAddress(currency?.address) ?? false) ?? [],
        [currencies]
    )

    const { chainId } = useActiveWeb3React()
    const tokenBalances = useTokenBalances(account, tokens)
    const containsETH = useMemo(() => currencies?.some((currency) => currency?.isNative) ?? false, [currencies])
    const ethBalance = useNativeCurrencyBalances(useMemo(() => (containsETH ? [account] : []), [containsETH, account]))

    return useMemo(
        () =>
            currencies?.map((currency) => {
                if (!account || !currency || currency.chainId !== chainId) return undefined
                if (currency.isToken) return tokenBalances[currency.address]
                if (currency.isNative) return ethBalance[account]
                return undefined
            }) ?? [],
        [account, chainId, currencies, ethBalance, tokenBalances]
    )
}

export default function useCurrencyBalance(
    currency
){
    const { account } = useActiveWeb3React()
    return useCurrencyBalances(
        account,
        useMemo(() => [currency], [currency])
    )[0]
}

export function useFormattedBalance(
    currency
) {
    const { account } = useActiveWeb3React()
    const balance = useCurrencyBalances(
        account,
        useMemo(() => [currency], [currency])
    )[0]

    return useMemo(() => balance, [balance])
}
