import { createMulticall } from '@uniswap/redux-multicall'
import { useInterfaceMulticall } from '../../hooks/useContract'
import useBlockNumber from '../../hooks/useBlockNumber'
import { useMemo } from 'react'
import useActiveWeb3React from "../../hooks/useWeb3";

const multicall = createMulticall()

export default multicall

function getBlocksPerFetchForChainId() {
    return 1
}

export function MulticallUpdater() {
    const { chainId } = useActiveWeb3React()
    const latestBlockNumber = useBlockNumber()
    const contract = useInterfaceMulticall()
    const listenerOptions = useMemo(
        () => ({
            blocksPerFetch: getBlocksPerFetchForChainId(),
        }),
        []
    )

    return (
        <multicall.Updater
            chainId={chainId}
            latestBlockNumber={latestBlockNumber}
            contract={contract}
            listenerOptions={listenerOptions}
            isDebug={true}
        />
    )
}
