import JSBI from "jsbi";

export const BASE_URL = 'https://dapp.oxchange.finance/'

export const networkName = "Polygon Mainnet"
export const defaultNetwork = 137

export const chainRPC = process.env.REACT_APP_RPC

export const NetworkInfo = {
    chainID: defaultNetwork,
    chainRPC: [chainRPC],
    networkName: networkName,
    explorer: ['https://polygonscan.com/'],
    symbol: 'MATIC',
    name: "Polygon Mainnet"
}

// token image default
export const defaultTokenImage = `${BASE_URL}tokens/default/logo.png`

// init pair hash
export const INIT_CODE_HASH = "0xbe4ebd33197952f2bf59e79155ee82853bb5b1909e02713f28e90feffb022434"

export const BIG_INT_ZERO = JSBI.BigInt(0)

