import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from "react-redux";

import { configureStore } from '@reduxjs/toolkit';
import {rootReducer} from "./rootReducer";
import { routingApi } from "../state/routing/slice";


export default configureStore(
    {
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({ thunk: true })
            .concat(routingApi.middleware)            
    }
)

const useSelector = useReduxSelector;

const useDispatch = () => useReduxDispatch();
export const useAppDispatch = () => useDispatch();

export { useSelector, useDispatch };