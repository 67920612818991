import { useMemo } from "react"
import { computeRealizedLPFeePercent, warningSeverity } from "../../utils/price"
import { TradeType } from "@uniswap/sdk"

export const SwapDetails = ({trade, allowedSlippage}) => {

    const { expectedOutputAmount, priceImpact } = useMemo(() => {
        if (!trade) return { expectedOutputAmount: undefined, priceImpact: undefined }
        const expectedOutputAmount = trade.outputAmount
        const realizedLpFeePercent = computeRealizedLPFeePercent(trade)
        const priceImpact = trade.priceImpact.subtract(realizedLpFeePercent)
        return { expectedOutputAmount, priceImpact }
    }, [trade])

    return (
        <div>
            <p>Expected Output <span>
                {expectedOutputAmount
                ? `${expectedOutputAmount.toSignificant(6)}  ${expectedOutputAmount.currency.symbol}`
                : '-'}
            </span></p>
            <p>Price Impact <span style={{color: warningSeverity(priceImpact) >= 2 ? "red": "", fontWeight: "bold"}}>{priceImpact ? `${priceImpact.multiply(-1).toFixed(2)}%` : '-'}</span></p>
            <hr />
            <p>{trade.tradeType === TradeType.EXACT_INPUT ? (
                <>Minimum received</>
              ) : (
                <>Maximum sent</>
              )}{' '}
              <>after slippage</> ({allowedSlippage.toFixed(2)}%) <span>{trade.tradeType === TradeType.EXACT_INPUT
                ? `${trade.minimumAmountOut(allowedSlippage).toSignificant(6)} ${trade.outputAmount.currency.symbol}`
                : `${trade.maximumAmountIn(allowedSlippage).toSignificant(6)} ${trade.inputAmount.currency.symbol}`}</span> </p>
        </div>
    )
}