import {defaultTokens, MaticNativeCurrency, OCToken} from "../config/tokens";
import useActiveWeb3React from "./useWeb3";
import {isAddress} from "../utils";
import {useBytes32TokenContract, useTokenContract} from "./useContract";
import {useSingleCallResult} from "./multicall";
import {NEVER_RELOAD} from "../state/multicall/hooks";
import {useMemo} from "react";
import {arrayify, parseBytes32String} from "ethers/lib/utils";
import {Token} from "@uniswap/sdk-core";

const BYTES32_REGEX = /^0x[a-fA-F0-9]{64}$/
export const DEFAULT_ERC20_DECIMALS = 18;

function parseStringOrBytes32(str, bytes32, defaultValue){
    return str && str.length > 0
        ? str
        : // need to check for proper bytes string and valid terminator
        bytes32 && BYTES32_REGEX.test(bytes32) && arrayify(bytes32)[31] === 0
            ? parseBytes32String(bytes32)
            : defaultValue
}

export const UNKNOWN_TOKEN_SYMBOL = 'UNKNOWN'
const UNKNOWN_TOKEN_NAME = 'Unknown Token'

export const useNative = () => {
    const { chainId } = useActiveWeb3React()
    return new MaticNativeCurrency(chainId)
}

export const useTokens = () => {
    return defaultTokens
}

export function useTokenFromActiveNetwork(tokenAddress) {
    const { chainId } = useActiveWeb3React()
    
    const formattedAddress = isAddress(tokenAddress)
    const tokenContract = useTokenContract(formattedAddress ? formattedAddress : undefined)
    const tokenContractBytes32 = useBytes32TokenContract(formattedAddress ? formattedAddress : undefined, false)

    const tokenName = useSingleCallResult(tokenContract, 'name', undefined, NEVER_RELOAD)
    const tokenNameBytes32 = useSingleCallResult(tokenContractBytes32, 'name', undefined, NEVER_RELOAD)
    const symbol = useSingleCallResult(tokenContract, 'symbol', undefined, NEVER_RELOAD)
    const symbolBytes32 = useSingleCallResult(tokenContractBytes32, 'symbol', undefined, NEVER_RELOAD)
    const decimals = useSingleCallResult(tokenContract, 'decimals', undefined, NEVER_RELOAD)
    
    const isLoading = useMemo(
        () => decimals.loading || symbol.loading || tokenName.loading,
        [decimals.loading, symbol.loading, tokenName.loading]
    )

    const parsedDecimals = useMemo(() => decimals?.result?.[0] ?? DEFAULT_ERC20_DECIMALS, [decimals.result])

    const parsedSymbol = useMemo(
        () => parseStringOrBytes32(symbol.result?.[0], symbolBytes32.result?.[0], UNKNOWN_TOKEN_SYMBOL),
        [symbol.result, symbolBytes32.result]
    )
    const parsedName = useMemo(
        () => parseStringOrBytes32(tokenName.result?.[0], tokenNameBytes32.result?.[0], UNKNOWN_TOKEN_NAME),
        [tokenName.result, tokenNameBytes32.result]
    )

    return useMemo(() => {
        if ( tokenAddress && tokenAddress.toLowerCase() === OCToken.address.toLowerCase() ) return new Token( chainId, OCToken.address, OCToken.decimals, OCToken.symbol, OCToken.name )
        // If the token is on another chain, we cannot fetch it on-chain, and it is invalid.
        if (typeof tokenAddress !== 'string' || !formattedAddress) return undefined
        // console.log(chainId, isLoading, parsedDecimals, parsedSymbol, parsedName)
        if (isLoading || !chainId) return null

        return new Token(chainId, formattedAddress, parsedDecimals, parsedSymbol, parsedName)
    }, [chainId, tokenAddress, formattedAddress, isLoading, parsedDecimals, parsedSymbol, parsedName])
}


export const useCurrency = ( tokenID ) => {
    const native = useNative();
    const isNative = Boolean(native && tokenID?.toUpperCase() === 'MATIC')
    const token = useTokenFromActiveNetwork(isNative ? native.address : tokenID ? tokenID : undefined)

    const wrappedNative = native?.wrapped
    // console.log(wrappedNative)
    if (wrappedNative?.address?.toUpperCase() === tokenID?.toUpperCase()) return wrappedNative

    return isNative ? native : token
}