import { parseUnits } from '@ethersproject/units'
import { CurrencyAmount } from '@uniswap/sdk-core'
import JSBI from 'jsbi'

export default function tryParseCurrencyAmount(
    value,
    currency
) {
    if (!value || !currency) {
        return undefined
    }
    try {
        const typedValueParsed = parseUnits(value, currency.decimals).toString()
        if (typedValueParsed !== '0') {
            return CurrencyAmount.fromRawAmount(currency, JSBI.BigInt(typedValueParsed))
        }
    } catch (error) {
        // fails if the user specifies too many decimal places of precision (or maybe exceed max uint?)
        console.debug(`Failed to parse input amount: "${value}"`, error)
    }
    return undefined
}
