// import {ethers} from "ethers";
import { ethers } from "ethers";
import Web3 from "web3";
import { chainRPC } from "../config";

const globalWeb3 = new ethers.providers.Web3Provider((new Web3(chainRPC)).currentProvider)

const getSigner = () => {
    const signer = globalWeb3;
    return signer;
}

export const getContract = ( address, abi, signer = null ) => {
    const signerOrProvider = signer ?? getSigner()
    return new ethers.Contract( address, abi, signerOrProvider )
}