import { createSlice } from '@reduxjs/toolkit'

import { DEFAULT_DEADLINE_FROM_NOW } from '../../config/constants'

const currentTimestamp = () => new Date().getTime()

function pairKey(token0Address: string, token1Address: string) {
    return `${token0Address};${token1Address}`
}

export const initialState = {
    taxServiceDismissals: 0,
    selectedWallet: undefined,
    userExpertMode: false,
    userLocale: null,
    userClientSideRouter: false,
    userHideClosedPositions: false,
    userSlippageTolerance: 'auto',
    userSlippageToleranceHasBeenMigratedToAuto: true,
    userDeadline: DEFAULT_DEADLINE_FROM_NOW,
    tokens: {},
    pairs: {},
    timestamp: currentTimestamp(),
    URLWarningVisible: true,
    hideUniswapWalletBanner: false,
    showSurveyPopup: undefined,
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateTaxServiceAcknowledgments(state, action) {
            state.taxServiceDismissals = action.payload.taxServiceDismissals
        },
        updateSelectedWallet(state, { payload: { wallet } }) {
            state.selectedWallet = wallet
        },
        updateUserExpertMode(state, action) {
            state.userExpertMode = action.payload.userExpertMode
            state.timestamp = currentTimestamp()
        },
        updateUserLocale(state, action) {
            state.userLocale = action.payload.userLocale
            state.timestamp = currentTimestamp()
        },
        updateUserSlippageTolerance(state, action) {
            state.userSlippageTolerance = action.payload.userSlippageTolerance
            state.timestamp = currentTimestamp()
        },
        updateUserDeadline(state, action) {
            state.userDeadline = action.payload.userDeadline
            state.timestamp = currentTimestamp()
        },
        updateUserClientSideRouter(state, action) {
            state.userClientSideRouter = action.payload.userClientSideRouter
        },
        updateHideClosedPositions(state, action) {
            state.userHideClosedPositions = action.payload.userHideClosedPositions
        },
        updateHideUniswapWalletBanner(state, action) {
            state.hideUniswapWalletBanner = action.payload.hideUniswapWalletBanner
        },
        addSerializedToken(state, { payload: { serializedToken } }) {
            if (!state.tokens) {
                state.tokens = {}
            }
            state.tokens[serializedToken.chainId] = state.tokens[serializedToken.chainId] || {}
            state.tokens[serializedToken.chainId][serializedToken.address] = serializedToken
            state.timestamp = currentTimestamp()
        },
        addSerializedPair(state, { payload: { serializedPair } }) {
            if (
                serializedPair.token0.chainId === serializedPair.token1.chainId &&
                serializedPair.token0.address !== serializedPair.token1.address
            ) {
                const chainId = serializedPair.token0.chainId
                state.pairs[chainId] = state.pairs[chainId] || {}
                state.pairs[chainId][pairKey(serializedPair.token0.address, serializedPair.token1.address)] = serializedPair
            }
            state.timestamp = currentTimestamp()
        },
    }
})

export const {
    addSerializedPair,
    addSerializedToken,
    updateTaxServiceAcknowledgments,
    updateSelectedWallet,
    updateHideClosedPositions,
    updateUserClientSideRouter,
    updateUserDeadline,
    updateUserExpertMode,
    updateUserLocale,
    updateUserSlippageTolerance,
    updateHideUniswapWalletBanner,
} = userSlice.actions
export default userSlice.reducer
