import { CurrencyAmount, TradeType } from '@uniswap/sdk-core'
import { SwapQuoter } from '@uniswap/v3-sdk'
import JSBI from 'jsbi'
import { useSingleContractWithCallData } from './multicall'
import { useMemo } from 'react'
import { InterfaceTrade, TradeState } from '../state/routing/types'

import { useAllV3Routes } from './useAllV3Routes'
import { useV3Quoter } from './useContract'

const DEFAULT_GAS_QUOTE = 100_000_000

export function useClientSideV3Trade(
    tradeType,
    amountSpecified?,
    otherCurrency?
) {
    const [currencyIn, currencyOut] = useMemo(
        () =>
            tradeType === TradeType.EXACT_INPUT
                ? [amountSpecified?.currency, otherCurrency]
                : [otherCurrency, amountSpecified?.currency],
        [tradeType, amountSpecified, otherCurrency]
    )
    const { routes, loading: routesLoading } = useAllV3Routes(currencyIn, currencyOut)

    const quoter = useV3Quoter()
    const quotesResults = useSingleContractWithCallData(
        quoter,
        amountSpecified
            ? routes.map((route) => SwapQuoter.quoteCallParameters(route, amountSpecified, tradeType).calldata)
            : [],
        {
            gasRequired: DEFAULT_GAS_QUOTE,
        }
    )

    return useMemo(() => {
        if (
            !amountSpecified ||
            !currencyIn ||
            !currencyOut ||
            quotesResults.some(({ valid }) => !valid) ||
            // skip when tokens are the same
            (tradeType === TradeType.EXACT_INPUT
                ? amountSpecified.currency?.equals(currencyOut)
                : amountSpecified.currency?.equals(currencyIn))
        ) {
            return {
                state: TradeState.INVALID,
                trade: undefined,
            }
        }

        if (routesLoading || quotesResults.some(({ loading }) => loading)) {
            return {
                state: TradeState.LOADING,
                trade: undefined,
            }
        }

        const { bestRoute, amountIn, amountOut } = quotesResults.reduce(
            (
                currentBest,
                { result },
                i
            ) => {
                if (!result) return currentBest

                // overwrite the current best if it's not defined or if this route is better
                if (tradeType === TradeType.EXACT_INPUT) {
                    const amountOut = CurrencyAmount.fromRawAmount(currencyOut, result.amountOut.toString())
                    if (currentBest.amountOut === null || JSBI.lessThan(currentBest.amountOut.quotient, amountOut.quotient)) {
                        return {
                            bestRoute: routes[i],
                            amountIn: amountSpecified,
                            amountOut,
                        }
                    }
                } else {
                    const amountIn = CurrencyAmount.fromRawAmount(currencyIn, result.amountIn.toString())
                    if (currentBest.amountIn === null || JSBI.greaterThan(currentBest.amountIn.quotient, amountIn.quotient)) {
                        return {
                            bestRoute: routes[i],
                            amountIn,
                            amountOut: amountSpecified,
                        }
                    }
                }

                return currentBest
            },
            {
                bestRoute: null,
                amountIn: null,
                amountOut: null,
            }
        )

        if (!bestRoute || !amountIn || !amountOut) {
            return {
                state: TradeState.NO_ROUTE_FOUND,
                trade: undefined,
            }
        }

        return {
            state: TradeState.VALID,
            trade: new InterfaceTrade({
                v2Routes: [],
                v3Routes: [
                    {
                        routev3: bestRoute,
                        inputAmount: amountIn,
                        outputAmount: amountOut,
                    },
                ],
                tradeType,
            }),
        }
    }, [amountSpecified, currencyIn, currencyOut, quotesResults, routes, routesLoading, tradeType])
}
