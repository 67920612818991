import {INPUTS} from "./config";
import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    independentField: INPUTS.TOKEN_A,
    tokenAValue: "",
    tokenBValue: "",
    startPrice: "",
    leftRange: "",
    rightRange: ""
}

const poolSlice = createSlice({
    name: "pool",
    initialState,
    reducers: {
        reset: ( state, action ) => state = initialState,
        inputLiquidity: (state, { payload: { input, noLiquidity, inputValue } } ) => {
            if ( noLiquidity ) {
                if ( input === state.independentField ) {
                    state.independentField = input
                    state.tokenAValue = inputValue
                } else {
                    state.independentField = input
                    state.tokenBValue = state.tokenAValue
                    state.tokenAValue = inputValue
                }
            } else {
                state.independentField = input
                state.tokenAValue = inputValue
                state.tokenBValue = ''
            }
        },
        fullRange: (state) => {
            state.leftRange = true
            state.rightRange = true
        },
        inputLeftRange: (state, action) => {
            state.leftRange = action.payload.inputValue
        },
        inputRightRange: ( state, action ) => {
            state.rightRange = action.payload.inputValue
        },
        inputPrice: ( state, action ) => {
            state.startPrice = action.payload.inputValue
        }
    }
})

export const { reset, inputLeftRange, inputLiquidity, inputPrice, inputRightRange, fullRange } = poolSlice.actions
export default poolSlice.reducer