import { Protocol } from '@uniswap/router-sdk'
import { routeAmountsToString } from '@uniswap/smart-order-router'
import { Pool } from '@uniswap/v3-sdk'

export function transformSwapRouteToGetQuoteResult(
    type,
    amount,
    {
        quote,
        quoteGasAdjusted,
        route,
        estimatedGasUsed,
        estimatedGasUsedQuoteToken,
        estimatedGasUsedUSD,
        gasPriceWei,
        methodParameters,
        blockNumber,
    }
) {
    const routeResponse = []

    for (const subRoute of route) {
        const { amount, quote, tokenPath } = subRoute

        const pools = subRoute.protocol === Protocol.V2 ? subRoute.route.pairs : subRoute.route.pools
        const curRoute = []
        for (let i = 0; i < pools.length; i++) {
            const nextPool = pools[i]
            const tokenIn = tokenPath[i]
            const tokenOut = tokenPath[i + 1]

            let edgeAmountIn = undefined
            if (i === 0) {
                edgeAmountIn = type === 'exactIn' ? amount.quotient.toString() : quote.quotient.toString()
            }

            let edgeAmountOut = undefined
            if (i === pools.length - 1) {
                edgeAmountOut = type === 'exactIn' ? quote.quotient.toString() : amount.quotient.toString()
            }

            if (nextPool instanceof Pool) {
                curRoute.push({
                    type: 'v3-pool',
                    tokenIn: {
                        chainId: tokenIn.chainId,
                        decimals: tokenIn.decimals,
                        address: tokenIn.address,
                        symbol: tokenIn.symbol,
                    },
                    tokenOut: {
                        chainId: tokenOut.chainId,
                        decimals: tokenOut.decimals,
                        address: tokenOut.address,
                        symbol: tokenOut.symbol,
                    },
                    fee: nextPool.fee.toString(),
                    liquidity: nextPool.liquidity.toString(),
                    sqrtRatioX96: nextPool.sqrtRatioX96.toString(),
                    tickCurrent: nextPool.tickCurrent.toString(),
                    amountIn: edgeAmountIn,
                    amountOut: edgeAmountOut,
                })
            } else {
                const reserve0 = nextPool.reserve0
                const reserve1 = nextPool.reserve1

                curRoute.push({
                    type: 'v2-pool',
                    tokenIn: {
                        chainId: tokenIn.chainId,
                        decimals: tokenIn.decimals,
                        address: tokenIn.address,
                        symbol: tokenIn.symbol,
                    },
                    tokenOut: {
                        chainId: tokenOut.chainId,
                        decimals: tokenOut.decimals,
                        address: tokenOut.address,
                        symbol: tokenOut.symbol,
                    },
                    reserve0: {
                        token: {
                            chainId: reserve0.currency.wrapped.chainId,
                            decimals: reserve0.currency.wrapped.decimals,
                            address: reserve0.currency.wrapped.address,
                            symbol: reserve0.currency.wrapped.symbol,
                        },
                        quotient: reserve0.quotient.toString(),
                    },
                    reserve1: {
                        token: {
                            chainId: reserve1.currency.wrapped.chainId,
                            decimals: reserve1.currency.wrapped.decimals,
                            address: reserve1.currency.wrapped.address,
                            symbol: reserve1.currency.wrapped.symbol,
                        },
                        quotient: reserve1.quotient.toString(),
                    },
                    amountIn: edgeAmountIn,
                    amountOut: edgeAmountOut,
                })
            }
        }

        routeResponse.push(curRoute)
    }

    const result = {
        methodParameters,
        blockNumber: blockNumber.toString(),
        amount: amount.quotient.toString(),
        amountDecimals: amount.toExact(),
        quote: quote.quotient.toString(),
        quoteDecimals: quote.toExact(),
        quoteGasAdjusted: quoteGasAdjusted.quotient.toString(),
        quoteGasAdjustedDecimals: quoteGasAdjusted.toExact(),
        gasUseEstimateQuote: estimatedGasUsedQuoteToken.quotient.toString(),
        gasUseEstimateQuoteDecimals: estimatedGasUsedQuoteToken.toExact(),
        gasUseEstimate: estimatedGasUsed.toString(),
        gasUseEstimateUSD: estimatedGasUsedUSD.toExact(),
        gasPriceWei: gasPriceWei.toString(),
        route: routeResponse,
        routeString: routeAmountsToString(route),
    }

    return result
}
