import {Percent} from '@uniswap/sdk-core'
import JSBI from 'jsbi'
import {MaticNativeCurrency, OCToken, WRAPPED_NATIVE_CURRENCY} from "./tokens";

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

// 30 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 30
export const L2_DEADLINE_FROM_NOW = 60 * 5

// transaction popup dismisal amounts
export const DEFAULT_TXN_DISMISS_MS = 25000
export const L2_TXN_DISMISS_MS = 5000

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis JSBI.BigInt
const BIPS_BASE = JSBI.BigInt(10000)
export const ONE_BIPS = new Percent(JSBI.BigInt(1), BIPS_BASE)

// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')


// custom bases
// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST = {
    137: [
        WRAPPED_NATIVE_CURRENCY(137),
        OCToken
    ],
}
export const ADDITIONAL_BASES = {
    137: []
}
export const CUSTOM_BASES = {
    137: [],
}

export const COMMON_BASES = {

    137: [
        new MaticNativeCurrency(137),
        OCToken
    ],
    80001: [
        WRAPPED_NATIVE_CURRENCY(80001),
        OCToken
    ],
}


