import { deepCopy } from '@ethersproject/properties'
import { StaticJsonRpcProvider } from '@ethersproject/providers'
import { isPlain } from '@reduxjs/toolkit'
import {NetworkInfo} from "./index";

class AppJsonRpcProvider extends StaticJsonRpcProvider {
    _blockCache = new Map()
    get blockCache() {
        // If the blockCache has not yet been initialized this block, do so by
        // setting a listener to clear it on the next block.
        if (!this._blockCache.size) {
            this.once('block', () => this._blockCache.clear())
        }
        return this._blockCache
    }

    constructor(chainId) {
        super(NetworkInfo.chainRPC, { chainId, name: "MATIC" })

        this.pollingInterval = 12000
    }

    send(method, params) {
        // Only cache eth_call's.
        if (method !== 'eth_call') return super.send(method, params)

        // Only cache if params are serializable.
        if (!isPlain(params)) return super.send(method, params)

        const key = `call:${JSON.stringify(params)}`
        const cached = this.blockCache.get(key)
        if (cached) {
            this.emit('debug', {
                action: 'request',
                request: deepCopy({ method, params, id: 'cache' }),
                provider: this,
            })
            return cached
        }

        const result = super.send(method, params)
        this.blockCache.set(key, result)
        return result
    }
}

export const RPC_PROVIDERS = {
    137: new AppJsonRpcProvider(137),
    80001: new AppJsonRpcProvider(80001)
}
