import {TickMath} from '@uniswap/v3-sdk'
import JSBI from "jsbi";
import {Price} from "@uniswap/sdk-core";

export const Q96 = JSBI.exponentiate(JSBI.BigInt(2), JSBI.BigInt(96))
export const Q192 = JSBI.exponentiate(Q96, JSBI.BigInt(2))

export function getTickToPrice(baseToken, quoteToken, tick) {
    if (!baseToken || !quoteToken || typeof tick !== 'number') {
        return undefined
    }
    return tickToPrice(baseToken, quoteToken, tick)
}

export function tickToPrice(baseToken, quoteToken, tick) {
    const sqrtRatioX96 = TickMath.getSqrtRatioAtTick(tick)

    const ratioX192 = JSBI.multiply(sqrtRatioX96, sqrtRatioX96)

    return baseToken.sortsBefore(quoteToken)
        ? new Price(baseToken, quoteToken, Q192, ratioX192)
        : new Price(baseToken, quoteToken, ratioX192, Q192)
}