import {combineReducers} from "@reduxjs/toolkit";
import accountSlice from '../state/account/slice'
import poolSlice from '../state/pool/slice'
import swapSlice from '../state/swap/slice'
import multicall from "../state/multicall";
import blockSlice from "../state/block/";
import { routingApi } from "../state/routing/slice";

const rootReducer = combineReducers({
    user: accountSlice,
    pool: poolSlice,
    swap: swapSlice,
    multicall: multicall.reducer,
    block: blockSlice,
    [routingApi.reducerPath]: routingApi.reducer,
})

export { rootReducer }