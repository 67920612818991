import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Protocol } from '@uniswap/router-sdk'
import { AlphaRouter } from '@uniswap/smart-order-router'
import { RPC_PROVIDERS } from '../../config/rpcProviders'
import { getClientSideQuote } from '../../hooks/getClientSideRouter'
import ms from 'ms.macro'
import qs from 'qs'

export const RouterPreference = {
    API: 'api',
    CLIENT: 'client',
    PRICE: 'price',
}

const routers = new Map()
function getRouter(chainId) {
    const provider = RPC_PROVIDERS[chainId]
    const router = new AlphaRouter({ chainId, provider })
    routers.set(chainId, router)
    if(router) {
        return router
    }

    throw new Error(`Router does not support this chain (chainId: ${chainId}).`)
}

const API_QUERY_PARAMS = {
    protocols: 'v2,v3,mixed',
}
const CLIENT_PARAMS = {
    protocols: [Protocol.V2, Protocol.V3, Protocol.MIXED],
}

export const routingApi = createApi({
    reducerPath: 'routingApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api.uniswap.org/v1/',
    }),
    endpoints: (build) => ({
        getQuote: build.query({
            async onQueryStarted(args, { queryFulfilled }) {
                console.log("onQueryStarted", args, queryFulfilled)
            },
            async queryFn(args, _api, _extraOptions, fetch) {
                const { tokenInAddress, tokenInChainId, tokenOutAddress, tokenOutChainId, amount, routerPreference, type } =
                    args

                try {
                    if (routerPreference === RouterPreference.API) {
                        const query = qs.stringify({
                            ...API_QUERY_PARAMS,
                            tokenInAddress,
                            tokenInChainId,
                            tokenOutAddress,
                            tokenOutChainId,
                            amount,
                            type,
                        })
                        return (await fetch(`quote?${query}`))
                    } else {
                        const router = getRouter(args.tokenInChainId)
                        return await getClientSideQuote(
                            args,
                            router,
                            // TODO(zzmp): Use PRICE_PARAMS for RouterPreference.PRICE.
                            // This change is intentionally being deferred to first see what effect router caching has.
                            CLIENT_PARAMS
                        )
                    }
                } catch (error) {
                    // TODO: fall back to client-side quoter when auto router fails.
                    // deprecate 'legacy' v2/v3 routers first.
                    return { error: { status: 'CUSTOM_ERROR', error: error.toString(), data: error } }
                }
            },
            keepUnusedDataFor: ms`10s`,
            extraOptions: {
                maxRetries: 0,
            },
        }),
    }),
})

export const { useGetQuoteQuery } = routingApi
