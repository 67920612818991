import { useEffect } from 'react';
import {connectorLocalStorageKey} from "../config/context";
import useAuth from "./useAuth";

export const useDirectConnect = () => {
    const { login } = useAuth();
    useEffect(() => {
        const connectorId = window.localStorage.getItem(connectorLocalStorageKey);

        if (connectorId) {
            login(connectorId);
        }
    }, [login]);
};
export default useDirectConnect;