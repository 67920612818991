import useActiveWeb3React from "./useWeb3";
import {useMemo} from "react";
import {getContract} from "../utils/getContract";
import tokenABI from '../config/abis/tokenABI.json';
import multicallABI from '../config/abis/multicall.json'
import nftManagerABI from '../config/abis/nftManager.json'
import ERC20_BYTES32 from '../config/abis/erc20BytesABI.json'
import QuoterABI from '../config/abis/Quoter.json'
import WETH_ABI from '../config/abis/weth_abi.json'
import EIP_2612 from '../config/abis/eip_2612.json'
import {CONTRACTS} from "../config/contracts";
import {WRAPPED_NATIVE_CURRENCY} from "../config/tokens";

export const useContract = (address, abi) => {
    const { library, account } = useActiveWeb3React()
    return useMemo(() => {
        if (!address || !abi) {
            return null
        }
        return getContract(address, abi, account ? library.getSigner() : null)
    }, [address, abi, library, account]);
}

export function useTokenContract(tokenAddress) {
    // console.log(tokenAddress)
    return useContract(tokenAddress, tokenABI)
}

export const useInterfaceMulticall = () => {
    const { chainId } = useActiveWeb3React()
    return useContract(chainId ? CONTRACTS.MULTICALL2_ADDRESS[chainId] : undefined, multicallABI)
}

export const usePositionManager = () => {
    const { chainId } = useActiveWeb3React()
    return useContract(chainId ? CONTRACTS.NONFUNGIBLE_TOKEN_POSITION_MANAGER_ADDRESS[chainId] : undefined, nftManagerABI)
}

export function useBytes32TokenContract(tokenAddress) {
    return useContract(tokenAddress, ERC20_BYTES32, false)
}

export function useV3Quoter() {
    const { chainId } = useActiveWeb3React()
    return useContract(CONTRACTS.QUOTER_ADDRESS[chainId], QuoterABI)
}

export function useWETHContract() {
    const { chainId } = useActiveWeb3React()
    return useContract(
        chainId ? WRAPPED_NATIVE_CURRENCY(chainId)?.address : undefined,
        WETH_ABI
    )
}

export function useEIP2612Contract(tokenAddress) {
    return useContract(tokenAddress, EIP_2612, false)
}