import React from 'react';
// import React from 'react';
import Dashboard from '../../Components/Dashboard/Dashboard'
// import Swap from '../../Components/Swap/Swap';
// import NftsCom from '../../Components/NftsCom/NftsCom';
// import Gridlistview from '../../Components/StakingCom/Gridlistview'
const Home = () => {
    return (
        <>
            <Dashboard />
            {/* <Swap /> */}
            {/* <NftsCom /> */}
            {/* <Gridlistview /> */}
        </>
    )
}

export default Home