import useActiveWeb3React from "./useWeb3";
import useBlockNumber from "./useBlockNumber";
import multicall from "../state/multicall";

export function useMultipleContractSingleData(
    addresses,
    contractInterface,
    methodName,
    callInputs
) {
    const { chainId, latestBlock } = useCallContext()
    return multicall.hooks.useMultipleContractSingleData(chainId, latestBlock, addresses, contractInterface, methodName, callInputs)
}

export function useSingleCallResult(...args) {
    const { chainId, latestBlock } = useCallContext()
    return multicall.hooks.useSingleCallResult(chainId, latestBlock, ...args)
}

export function useSingleContractMultipleData(
    multicallContract,
    methodName,
    callInputs
) {
    const { chainId, latestBlock } = useCallContext()
    return multicall.hooks.useSingleContractMultipleData(chainId, latestBlock, multicallContract, methodName, callInputs)
}

export function useSingleContractWithCallData(
    contract,
    callDatas,
    options
) {
    const { chainId, latestBlock } = useCallContext()
    return multicall.hooks.useSingleContractWithCallData(chainId, latestBlock, contract, callDatas, options)
}


function useCallContext() {
    const { chainId } = useActiveWeb3React()
    const latestBlock = useBlockNumber()
    return { chainId, latestBlock }
}