import { CurrencyAmount, Token, TradeType } from '@uniswap/sdk-core'
import { AlphaRouter, AlphaRouterConfig } from '@uniswap/smart-order-router'

import JSBI from 'jsbi'
import { transformSwapRouteToGetQuoteResult } from '../utils/transformSwapRouteToGetQuoteResult'

export function toSupportedChainId(chainId) {
    const numericChainId = parseInt(chainId)
    if (numericChainId === 137 || numericChainId === 8001) return numericChainId
    return undefined
}
export function isSupportedChainId(chainId) {
    if (chainId === undefined) return false
    return toSupportedChainId(chainId) !== undefined
}

async function getQuote(
    {
        type,
        tokenIn,
        tokenOut,
        amount: amountRaw,
    },
    router,
    config
) {
    const currencyIn = new Token(tokenIn.chainId, tokenIn.address, tokenIn.decimals, tokenIn.symbol)
    const currencyOut = new Token(tokenOut.chainId, tokenOut.address, tokenOut.decimals, tokenOut.symbol)

    const baseCurrency = type === 'exactIn' ? currencyIn : currencyOut
    const quoteCurrency = type === 'exactIn' ? currencyOut : currencyIn
    const amount = CurrencyAmount.fromRawAmount(baseCurrency, JSBI.BigInt(amountRaw))

    const swapRoute = await router.route(
        amount,
        quoteCurrency,
        type === 'exactIn' ? TradeType.EXACT_INPUT : TradeType.EXACT_OUTPUT,
        /*swapConfig=*/ undefined,
        config
    )

    if (!swapRoute) throw new Error('Failed to generate client side quote')

    return { data: transformSwapRouteToGetQuoteResult(type, amount, swapRoute) }
}

export async function getClientSideQuote(
    {
        tokenInAddress,
        tokenInChainId,
        tokenInDecimals,
        tokenInSymbol,
        tokenOutAddress,
        tokenOutChainId,
        tokenOutDecimals,
        tokenOutSymbol,
        amount,
        type,
    }: QuoteArguments,
    router: AlphaRouter,
    config: Partial<AlphaRouterConfig>
) {
    return getQuote(
        {
            type,
            tokenIn: {
                address: tokenInAddress,
                chainId: tokenInChainId,
                decimals: tokenInDecimals,
                symbol: tokenInSymbol,
            },
            tokenOut: {
                address: tokenOutAddress,
                chainId: tokenOutChainId,
                decimals: tokenOutDecimals,
                symbol: tokenOutSymbol,
            },
            amount,
        },
        router,
        config
    )
}
