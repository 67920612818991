import {
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle, CModal,
    CModalBody,
    CModalHeader,
    CModalTitle
} from "@coreui/react";
import { Chart } from "react-charts";
import React, { useCallback, useEffect, useState } from "react";
import { TokenSelect } from "../SelectToken/TokenSelect";
import { MATIC, OCToken, WRAPPED_NATIVE_CURRENCY } from "../../config/tokens";
import {
    useRangeHopCallbacks,
    useV3PoolActionHandlers,
    useV3PoolDerivedState,
    useV3PoolState
} from "../../state/pool/hooks";
import { usePositionManager } from "../../hooks/useContract";
import { useNavigate, useParams } from "react-router";
import { useV3PositionFromTokenId } from "../../hooks/useV3Positions";
import { BigNumber } from "@ethersproject/bignumber";
import { useDerivedPositionInfo } from "../../hooks/useDerivedPositionInfo";
import { FeeAmount, NonfungiblePositionManager } from "@uniswap/v3-sdk";
import { useCurrency } from "../../hooks/Tokens";
import { Bound, INPUTS } from "../../state/pool/config";
import { maxAmountSpend } from "../../utils/maxAmountSpend";
import { Percent } from "@uniswap/sdk-core";
import { useApproveCallback } from "../../hooks/useApproveCallback";
import { CONTRACTS } from "../../config/contracts";
import useActiveWeb3React from "../../hooks/useWeb3";
import { useUserSlippageToleranceWithDefault } from "../../state/account/hooks";
import { ZERO_PERCENT } from "../../config/constants";
import useTransactionDeadline from "../../hooks/useTransactionDeadline";
import { calculateGasMargin } from "../../utils/calculateGasMargin";
import { currencyId } from "../../utils/currencyId";
import { useSearchParams } from "react-router-dom";
import { ApprovalState } from "../../hooks/useApproval";
import usePrevious from "../../hooks/usePrevious";
import { RangeSelector } from "./RangeSelector";
import { InputPanel } from "./InputPanel";
import { Toaster } from "react-hot-toast";
import { showToast } from "../../utils";
import LiquidityModal from "../LiquidityModal/LiquidityModal";



const DEFAULT_ADD_IN_RANGE_SLIPPAGE_TOLERANCE = new Percent(50, 10_000)

export const AddLiquidity = (props) => {
    const [visible, setVisible] = useState(false)
    // Preview Modal
    const [visiblePreview, setVisiblePreview] = useState(false)
    const data = React.useMemo(
        () => [
            {
                label: 'Series 1',
                data: [[0, 1], [1, 2], [2, 4], [3, 2], [4, 7]]
            },
            {
                label: 'Series 2',
                data: [[0, 3], [1, 1], [2, 5], [3, 6], [4, 4]]
            }
        ],
        []
    )

    const axes = React.useMemo(
        () => [
            { primary: true, type: 'linear', position: 'bottom' },
            { type: 'linear', position: 'left' }
        ],
        []
    )

    // pool logic
    const navigate = useNavigate()
    const { chainId, library: provider, account } = useActiveWeb3React()
    const deadline = useTransactionDeadline()
    const [attemptingTxn, setAttemptingTxn] = useState(false)

    const {
        currencyIdA,
        currencyIdB,
        feeAmount: feeAmountFromUrl,
        tokenId,
    } = useParams()

    const nftManager = usePositionManager()

    const { position: existingPositionDetails, loading: positionLoading } = useV3PositionFromTokenId(
        tokenId ? BigNumber.from(tokenId) : undefined
    )

    const hasExistingPosition = !!existingPositionDetails && !positionLoading
    const { position: existingPosition } = useDerivedPositionInfo(existingPositionDetails)

    const feeAmount =
        feeAmountFromUrl && Object.values(FeeAmount).includes(parseFloat(feeAmountFromUrl))
            ? parseFloat(feeAmountFromUrl)
            : undefined

    const baseCurrency = useCurrency(currencyIdA ?? MATIC.address)
    const currencyB = useCurrency(currencyIdB ?? OCToken.address)
    const quoteCurrency =
        baseCurrency && currencyB && baseCurrency.wrapped.equals(currencyB.wrapped) ? undefined : currencyB

    const { independentField, tokenAValue: typedValue, startPrice: startPriceTypedValue } = useV3PoolState()

    const {
        pool,
        ticks,
        dependentField,
        price,
        pricesAtTicks,
        pricesAtLimit,
        parsedAmounts,
        currencyBalances,
        position,
        noLiquidity,
        currencies,
        errorMessage,
        invalidPool,
        invalidRange,
        outOfRange,
        depositADisabled,
        depositBDisabled,
        invertPrice,
        ticksAtLimit,
    } = useV3PoolDerivedState(
        baseCurrency ?? undefined,
        quoteCurrency ?? undefined,
        feeAmount,
        baseCurrency ?? undefined,
        existingPosition
    )

    const { onInputA, onInputB, onLeftRangeInput, onRightRangeInput, onStartPriceInput } = useV3PoolActionHandlers(noLiquidity)

    const isValid = !errorMessage && !invalidRange

    const maxAmounts = [INPUTS.TOKEN_A, INPUTS.TOKEN_B].reduce(
        (accumulator, field) => {
            return {
                ...accumulator,
                [field]: maxAmountSpend(currencyBalances[field]),
            }
        },
        {}
    )

    const atMaxAmounts = [INPUTS.TOKEN_A, INPUTS.TOKEN_B].reduce(
        (accumulator, field) => {
            return {
                ...accumulator,
                [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0'),
            }
        },
        {}
    )

    // console.log(parsedAmounts[INPUTS.TOKEN_A])

    const [approvalA, approveACallback] = useApproveCallback(
        parsedAmounts[INPUTS.TOKEN_A],
        chainId ? CONTRACTS.NONFUNGIBLE_TOKEN_POSITION_MANAGER_ADDRESS[chainId] : undefined
    )
    const [approvalB, approveBCallback] = useApproveCallback(
        parsedAmounts[INPUTS.TOKEN_B],
        chainId ? CONTRACTS.NONFUNGIBLE_TOKEN_POSITION_MANAGER_ADDRESS[chainId] : undefined
    )

    const allowedSlippage = useUserSlippageToleranceWithDefault(
        outOfRange ? ZERO_PERCENT : DEFAULT_ADD_IN_RANGE_SLIPPAGE_TOLERANCE
    )

    async function onAdd() {
        if (!chainId || !provider || !account) return

        if (!nftManager || !baseCurrency || !quoteCurrency) {
            return
        }

        if (position && account && deadline) {
            const useNative = baseCurrency.isNative ? baseCurrency : quoteCurrency.isNative ? quoteCurrency : undefined
            const { calldata, value } =
                hasExistingPosition && tokenId
                    ? NonfungiblePositionManager.addCallParameters(position, {
                        tokenId,
                        slippageTolerance: allowedSlippage,
                        deadline: deadline.toString(),
                        useNative,
                    })
                    : NonfungiblePositionManager.addCallParameters(position, {
                        slippageTolerance: allowedSlippage,
                        recipient: account,
                        deadline: deadline.toString(),
                        useNative,
                        createPool: noLiquidity,
                    })

            let txn = {
                to: CONTRACTS.NONFUNGIBLE_TOKEN_POSITION_MANAGER_ADDRESS[chainId],
                data: calldata,
                value,
            }

            setAttemptingTxn(true)

            provider
                .getSigner()
                .estimateGas(txn)
                .then(async (estimate) => {
                    const newTxn = {
                        ...txn,
                        gasLimit: calculateGasMargin(estimate),
                    }

                    const sendtxn = await provider
                        .getSigner()
                        .sendTransaction(newTxn)

                    await showToast('Transaction Submitted', {
                        type: "promise",
                        promise: sendtxn.wait(),
                        pending: "Transaction Submitted",
                        success: "Transaction Confirmed",
                    })
                    setAttemptingTxn(false)
                })
                .catch((error) => {
                    console.error('Failed to send transaction', error)
                    setAttemptingTxn(false)
                    // we only care if the error is something _other_ than the user rejected the tx
                    if (error?.code !== 4001) {
                        console.error(error)
                    }
                    showToast(error.reason ? error.reason : (error.data ? error.data.message : error.message), {
                        type: "error",
                    })
                })
        } else {
            return
        }
    }

    const handleCurrencySelect = useCallback(
        (currencyNew, currencyIdOther) => {
            const currencyIdNew = currencyId(currencyNew)

            if (currencyIdNew === currencyIdOther) {
                // not ideal, but for now clobber the other if the currency ids are equal
                return [currencyIdNew, undefined]
            } else {
                const isETHOrWETHNew =
                    currencyIdNew === 'MATIC' ||
                    (chainId !== undefined && currencyIdNew === WRAPPED_NATIVE_CURRENCY(chainId)?.address)
                const isETHOrWETHOther =
                    currencyIdOther !== undefined &&
                    (currencyIdOther === 'MATIC' ||
                        (chainId !== undefined && currencyIdOther === WRAPPED_NATIVE_CURRENCY(chainId)?.address))

                if (isETHOrWETHNew && isETHOrWETHOther) {
                    return [currencyIdNew, undefined]
                } else {
                    return [currencyIdNew, currencyIdOther]
                }
            }
        },
        [chainId]
    )

    const handleCurrencyASelect = useCallback(
        (currencyANew) => {
            const [idA, idB] = handleCurrencySelect(currencyANew, currencyIdB)
            if (idB === undefined) {
                navigate(`/pool/${idA}`)
            } else {
                navigate(`/pool/${idA}/${idB}`)
            }
        },
        [handleCurrencySelect, currencyIdB, navigate]
    )

    const handleCurrencyBSelect = useCallback(
        (currencyBNew) => {
            const [idB, idA] = handleCurrencySelect(currencyBNew, currencyIdA)
            if (idA === undefined) {
                navigate(`/pool/${idB}`)
            } else {
                navigate(`/pool/${idA}/${idB}`)
            }
        },
        [handleCurrencySelect, currencyIdA, navigate]
    )

    const handleFeePoolSelect = useCallback(
        (newFeeAmount) => {
            onLeftRangeInput('')
            onRightRangeInput('')
            navigate(`/pool/${currencyIdA}/${currencyIdB}/${newFeeAmount}`)
        },
        [currencyIdA, currencyIdB, navigate, onLeftRangeInput, onRightRangeInput]
    )

    const clearAll = useCallback(() => {
        onInputA('')
        onInputB('')
        onLeftRangeInput('')
        onRightRangeInput('')
        navigate(`/pool`)
    }, [navigate, onInputA, onInputB, onLeftRangeInput, onRightRangeInput])

    const { [Bound.LOWER]: tickLower, [Bound.UPPER]: tickUpper } = ticks
    const { [Bound.LOWER]: priceLower, [Bound.UPPER]: priceUpper } = pricesAtTicks

    const { getDecrementLower, getIncrementLower, getDecrementUpper, getIncrementUpper, getSetFullRange } =
        useRangeHopCallbacks(baseCurrency ?? undefined, quoteCurrency ?? undefined, feeAmount, tickLower, tickUpper, pool)


    const showApprovalA =
        approvalA !== ApprovalState.APPROVED && !!parsedAmounts[INPUTS.TOKEN_A]
    const showApprovalB =
        approvalB !== ApprovalState.APPROVED && !!parsedAmounts[INPUTS.TOKEN_B]

    const formattedAmounts = {
        [independentField]: typedValue,
        [dependentField]: parsedAmounts[dependentField]?.toSignificant(6) ?? '',
    }

    const [searchParams, setSearchParams] = useSearchParams()

    const handleSetFullRange = useCallback(() => {
        getSetFullRange()

        const minPrice = pricesAtLimit[Bound.LOWER]
        if (minPrice) searchParams.set('minPrice', minPrice.toSignificant(5))
        const maxPrice = pricesAtLimit[Bound.UPPER]
        if (maxPrice) searchParams.set('maxPrice', maxPrice.toSignificant(5))
        setSearchParams(searchParams)
    }, [getSetFullRange, pricesAtLimit, searchParams, setSearchParams])

    // START: sync values with query string
    const oldSearchParams = usePrevious(searchParams)
    // use query string as an input to onInput handlers
    useEffect(() => {
        const minPrice = searchParams.get('minPrice')
        const oldMinPrice = oldSearchParams?.get('minPrice')
        if (
            minPrice &&
            typeof minPrice === 'string' &&
            !isNaN(minPrice) &&
            (!oldMinPrice || oldMinPrice !== minPrice)
        ) {
            onLeftRangeInput(minPrice)
        }
        // disable eslint rule because this hook only cares about the url->input state data flow
        // input state -> url updates are handled in the input handlers
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams, oldSearchParams, onLeftRangeInput])
    useEffect(() => {
        const maxPrice = searchParams.get('maxPrice')
        const oldMaxPrice = oldSearchParams?.get('maxPrice')
        if (
            maxPrice &&
            typeof maxPrice === 'string' &&
            !isNaN(maxPrice) &&
            (!oldMaxPrice || oldMaxPrice !== maxPrice)
        ) {
            onRightRangeInput(maxPrice)
        }
    }, [searchParams, oldSearchParams, onRightRangeInput])

    useEffect(() => {
        if (!currencyIdA || !currencyIdB) {
            navigate(`/pool/MATIC/${OCToken.address}`)
        }
    }, [currencyIdA, currencyIdB, navigate])

    const [approvingA, setApprovingA] = useState(false)
    const [approvingB, setApprovingB] = useState(false)

    const overrideApproveACall = useCallback(() => {
        setApprovingA(true)
        approveACallback().then(() => {
            setApprovingA(false)
        }).catch(() => {
            setApprovingA(false)
        })
    }, [approveACallback])

    const overrideApproveBCall = useCallback(() => {
        setApprovingB(true)
        approveBCallback().then(() => {
            setApprovingB(false)
        }).catch(() => {
            setApprovingB(false)
        })
    }, [approveBCallback])

    const Buttons = () => {
        return <>
            {
                !account ? <button className="bxBtnMainFoot">Connect Wallet</button>
                    : <div>
                        {(approvalA === ApprovalState.NOT_APPROVED ||
                            approvalA === ApprovalState.PENDING ||
                            approvalB === ApprovalState.NOT_APPROVED ||
                            approvalB === ApprovalState.PENDING) &&
                            isValid && (
                                <div>
                                    {
                                        showApprovalA && <button onClick={overrideApproveACall} className="bxBtnMainFoot" disabled={approvalA === ApprovalState.PENDING || approvingA}>
                                            {approvalA === ApprovalState.PENDING ? (
                                                <span>
                                                    Approving {currencies[INPUTS.TOKEN_A]?.symbol}...
                                                </span>
                                            ) : (
                                                'Approve ' + currencies[INPUTS.TOKEN_A]?.symbol
                                            )}
                                        </button>
                                    }
                                    {
                                        showApprovalB && <button onClick={overrideApproveBCall} className="bxBtnMainFoot mt-3" disabled={approvalB === ApprovalState.PENDING || approvingB}>
                                            {approvalB === ApprovalState.PENDING ? (
                                                <span>
                                                    Approving {currencies[INPUTS.TOKEN_B]?.symbol}...
                                                </span>
                                            ) : (
                                                'Approve ' + currencies[INPUTS.TOKEN_B]?.symbol
                                            )}
                                        </button>
                                    }
                                </div>
                            )}
                        <button
                            className="bxBtnMainFoot mt-3"
                            onClick={() => {
                                onAdd().then(r => console.debug(r))
                            }}
                            disabled={
                                attemptingTxn ||
                                !isValid ||
                                (approvalA !== ApprovalState.APPROVED && !depositADisabled) ||
                                (approvalB !== ApprovalState.APPROVED && !depositBDisabled)
                            }
                        >
                            {errorMessage ? errorMessage : (hasExistingPosition ? 'Update' : 'Supply')}
                        </button>
                    </div>
            }
        </>
    }

    return (
        <>
            <button className="bg-button" onClick={() => setVisible(!visible)}>
                <span>+ New Position</span>
                <svg id="Group_22318" data-name="Group 22318" xmlns="http://www.w3.org/2000/svg" width="359.843" height="87.528" viewBox="0 0 359.843 87.528">
                    <rect id="Rectangle_11605" data-name="Rectangle 11605" width="300.488" height="43.679" transform="translate(29.173 21.885)" fill="#01ffff" opacity="0.15" />
                    <path id="Path_18821" data-name="Path 18821" d="M1648.708,1296.062H1341.566V1247.5h307.142Zm-303.945-1.327h300.748v-45.913H1344.763Z" transform="translate(-1315.216 -1228.015)" fill="#01ffff" opacity="0.15" />
                    <path id="Path_18822" data-name="Path 18822" d="M1323.747,1273.87v-37.124H1334.2v44.288Z" transform="translate(-1323.747 -1226.292)" fill="#01ffff" />
                    <path id="Path_18823" data-name="Path 18823" d="M1388.172,1229.676h-64.425v10.455h71.589Z" transform="translate(-1323.747 -1229.676)" fill="#01ffff" />
                    <path id="Path_18824" data-name="Path 18824" d="M1427.013,1233.211h-63.606v5.228h67.188Z" transform="translate(-1305.458 -1227.984)" fill="#01ffff" />
                    <path id="Path_18825" data-name="Path 18825" d="M1418.253,1233.211h-6.489l3.582,5.228h6.489Z" transform="translate(-1344.998 -1227.984)" fill="#01ffff" />
                    <path id="Path_18826" data-name="Path 18826" d="M1427.013,1233.211h-6.488l3.582,5.228h6.489Z" transform="translate(-1347.113 -1227.984)" fill="#01ffff" />
                    <path id="Path_18827" data-name="Path 18827" d="M1435.774,1233.211h-6.489l3.582,5.228h6.489Z" transform="translate(-1349.229 -1227.984)" fill="#01ffff" />
                    <path id="Path_18828" data-name="Path 18828" d="M1761.8,1229.676h37.124v10.455h-44.288Z" transform="translate(-1449.54 -1229.676)" fill="#01ffff" />
                    <path id="Path_18829" data-name="Path 18829" d="M1795.046,1294.1v-64.425h-10.455v71.589Z" transform="translate(-1435.203 -1229.676)" fill="#01ffff" />
                    <path id="Path_18830" data-name="Path 18830" d="M1789.818,1332.942v-63.606h-5.227v67.188Z" transform="translate(-1435.203 -1259.759)" fill="#01ffff" />
                    <path id="Path_18831" data-name="Path 18831" d="M1795.046,1400.187v37.124h-10.455v-44.288Z" transform="translate(-1435.203 -1360.237)" fill="#01ffff" />
                    <path id="Path_18832" data-name="Path 18832" d="M1750.412,1433.427h64.425v-10.455h-71.589Z" transform="translate(-1454.994 -1345.9)" fill="#01ffff" />
                    <path id="Path_18833" data-name="Path 18833" d="M1710.146,1428.2h63.606v-5.228h-67.188Z" transform="translate(-1471.858 -1345.9)" fill="#01ffff" />
                    <path id="Path_18834" data-name="Path 18834" d="M1367.941,1433.427h-37.124v-10.455H1375.1Z" transform="translate(-1320.362 -1345.9)" fill="#01ffff" />
                    <path id="Path_18835" data-name="Path 18835" d="M1323.747,1388.794v64.425H1334.2V1381.63Z" transform="translate(-1323.747 -1365.691)" fill="#01ffff" />
                    <path id="Path_18836" data-name="Path 18836" d="M1327.282,1348.528v63.606h5.227v-67.188Z" transform="translate(-1322.054 -1334.183)" fill="#01ffff" />
                    <path id="Path_18837" data-name="Path 18837" d="M1671.511,1305.127H1329.917v-69.281h341.594Zm-340.245-.663h338.9V1236.51h-338.9Z" transform="translate(-1322.076 -1227.316)" fill="#006f76" />
                    <path id="Path_18838" data-name="Path 18838" d="M1328.278,1319.735l-2.153-1.6V1261.1h2.153Z" transform="translate(-1322.608 -1246.798)" fill="#01ffff" />
                </svg>
            </button>
            <CModal alignment="center" visible={visible} onClose={() => setVisible(false)} className="newPositionMdl">
                <CModalHeader>
                    <CModalTitle>Add Liquidity</CModalTitle>
                    <div className='headRight'>
                        <p onClick={clearAll}>Clear All</p>
                        <CDropdown hidden>
                            <CDropdownToggle color="secondary"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="Settings__StyledMenuIcon-sc-fxnuer-0 llccoy"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg></CDropdownToggle>
                            <CDropdownMenu>
                                <CDropdownItem href="#">Action</CDropdownItem>
                                <CDropdownItem href="#">Another action</CDropdownItem>
                                <CDropdownItem href="#">Something else here</CDropdownItem>
                            </CDropdownMenu>
                        </CDropdown>
                    </div>
                </CModalHeader>
                <CModalBody>
                    <div className='popMaininrBox'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <h2 className='titileBox'>Select Pair</h2>
                                <div className='row selectPairBox'>
                                    <TokenSelect onTokenSelect={handleCurrencyASelect} token={currencies[INPUTS.TOKEN_A] ?? null} />
                                    <TokenSelect onTokenSelect={handleCurrencyBSelect} token={currencies[INPUTS.TOKEN_B] ?? null} />
                                </div>
                                <div className='feeTierBox'>
                                    <p>Fee tier</p>
                                    <span>The % you will earn in fees.</span>
                                    {/*<button>Hide</button>*/}
                                </div>
                                <div className='bestForBox'>
                                    <div className={`bstfrbxInr ${feeAmount === FeeAmount.LOW && 'active'}`} onClick={() => {
                                        handleFeePoolSelect(FeeAmount.LOW)
                                    }}>
                                        <p>0.05%</p>
                                        <span>Best for stable pairs.</span>
                                        <div className='checkbox'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="Button__ResponsiveCheck-sc-tdn257-14 hEdoZT"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                        </div>
                                    </div>
                                    <div className={`bstfrbxInr ${feeAmount === FeeAmount.MEDIUM && 'active'}`} onClick={() => {
                                        handleFeePoolSelect(FeeAmount.MEDIUM)
                                    }}>
                                        <p>0.3%</p>
                                        <span>Best for most pairs..</span>
                                        <div className='checkbox'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="Button__ResponsiveCheck-sc-tdn257-14 hEdoZT"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                        </div>
                                    </div>
                                    <div className={`bstfrbxInr ${feeAmount === FeeAmount.HIGH && 'active'}`} onClick={() => {
                                        handleFeePoolSelect(FeeAmount.HIGH)
                                    }}>
                                        <p>1%</p>
                                        <span>Best for exotic pairs.</span>
                                        <div className='checkbox'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="Button__ResponsiveCheck-sc-tdn257-14 hEdoZT"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                {
                                    !noLiquidity && <h2 className='titileBox'>Set Price Range</h2>
                                }
                                {price && baseCurrency && quoteCurrency && !noLiquidity && (
                                    <div className={'text-center'}>
                                        <span>Current Price: {invertPrice ? price.invert().toSignificant(6) : price.toSignificant(6)} {quoteCurrency?.symbol} per {baseCurrency.symbol}</span>
                                    </div>
                                )}
                                {
                                    noLiquidity && <h2 className='titileBox'>Set Starting Price</h2>
                                }
                                {
                                    !noLiquidity && <div className='setPriceRangeBx'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="22 12 16 12 14 15 10 15 8 12 2 12"></polyline><path d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"></path></svg>
                                        <p>Your position will appear here.</p>
                                        {/* Chart Is Hide (Remove d-none class) */}
                                        <div className='d-none' style={{ width: '100%', height: '300px' }}>
                                            <Chart data={data} axes={axes} />
                                        </div>
                                    </div>
                                }
                                {/* Show After select Pair now this is Hide */}
                                {noLiquidity && <div className="setStatingPrice">
                                    <div className="infoBx">
                                        <p>This pool must be initialized before you can add liquidity. To initialize, select a starting price for the pool. Then, enter your liquidity price range and deposit amount. Gas fees will be higher than usual due to the initialization transaction.
                                        </p>
                                    </div>
                                    <div className="inptBx">
                                        <input type="text" value={startPriceTypedValue} onInput={(e) => onStartPriceInput(e.target.value)} placeholder={`Enter ${quoteCurrency?.symbol} per ${baseCurrency.symbol}`} />
                                    </div>
                                    <div className="currentPriceBx">
                                        <p>Current {baseCurrency?.symbol} Price:</p>
                                        <span>
                                            {price ? <>
                                                <b>{invertPrice ? price?.invert()?.toSignificant(6) : price?.toSignificant(6)}</b>
                                                {quoteCurrency?.symbol}
                                            </> : '-'}
                                        </span>
                                    </div>
                                </div>}
                            </div>
                            <div className='col-lg-6'>
                                {/*<h2 className='titileBox'>Set Price Range</h2>*/}
                                <div className="minMaxPrice">
                                    <RangeSelector
                                        priceLower={priceLower}
                                        priceUpper={priceUpper}
                                        getDecrementLower={getDecrementLower}
                                        getIncrementLower={getIncrementLower}
                                        getDecrementUpper={getDecrementUpper}
                                        getIncrementUpper={getIncrementUpper}
                                        onLeftRangeInput={onLeftRangeInput}
                                        onRightRangeInput={onRightRangeInput}
                                        currencyA={baseCurrency}
                                        currencyB={quoteCurrency}
                                        feeAmount={feeAmount}
                                        ticksAtLimit={ticksAtLimit}
                                    />
                                </div>
                                {!noLiquidity && <button className="fullRngBtn" onClick={handleSetFullRange}>Full Range</button>}
                                {
                                    outOfRange && <span>
                                        <p className="error">Your position will not earn fees or be used in trades until the market price moves into
                                            your range.</p>
                                    </span>
                                }
                                {
                                    invalidRange && <span>
                                        <p className="error">Min price must be lower than the max price.</p>
                                    </span>
                                }
                                <button className="fullRngBtn" onClick={() => setVisiblePreview(!visiblePreview)}>Preview</button>
                            </div>
                            <div className='col-lg-6'>
                                <h2 className='titileBox'>
                                    {hasExistingPosition ? "Add More Liquidity" : "Deposit Amounts"}
                                </h2>
                                <div className={
                                    `inputPanel ${(tickLower === undefined || tickUpper === undefined || invalidPool || invalidRange) && 'disabled'}`
                                }>
                                    <div className="depositAmuntBx">
                                        <InputPanel
                                            value={formattedAmounts[INPUTS.TOKEN_A]}
                                            onUserInput={onInputA}
                                            onMaxSelect={() => {
                                                onInputA(maxAmounts[INPUTS.TOKEN_A]?.toExact() ?? '')
                                            }}
                                            showMaxButton={!atMaxAmounts[INPUTS.TOKEN_A]}
                                            currency={currencies[INPUTS.TOKEN_A] ?? null}
                                            id="add-liquidity-input-tokena"
                                            locked={depositADisabled}
                                        />
                                    </div>
                                    <div className="depositAmuntBx">
                                        <InputPanel
                                            value={formattedAmounts[INPUTS.TOKEN_B]}
                                            onUserInput={onInputB}
                                            onMaxSelect={() => {
                                                onInputB(maxAmounts[INPUTS.TOKEN_B]?.toExact() ?? '')
                                            }}
                                            showMaxButton={!atMaxAmounts[INPUTS.TOKEN_B]}
                                            currency={currencies[INPUTS.TOKEN_B] ?? null}
                                            id="add-liquidity-input-tokenb"
                                            locked={depositBDisabled}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <Buttons />
                            </div>
                            <div className='col-lg-6 p-0'></div>
                        </div>
                    </div>
                    <Toaster position={"bottom-right"} />
                </CModalBody>
                {/* <CModalFooter>
                    <CButton color="secondary" onClick={() => setVisible(false)}>
                        Close
                    </CButton>
                    <CButton color="primary">Save changes</CButton>
                </CModalFooter> */}
            </CModal>
            <LiquidityModal props={props} visiblePreview={visiblePreview} setVisiblePreview={setVisiblePreview} />
        </>
    )
}