import { Trade } from '@uniswap/router-sdk'

export const TradeState = {
    LOADING: 0,
    INVALID: 1,
    NO_ROUTE_FOUND: 2,
    VALID: 3,
    SYNCING: 4
}

export class InterfaceTrade extends Trade {
    gasUseEstimateUSD
    blockNumber

    constructor({
                    gasUseEstimateUSD,
                    blockNumber,
                    ...routes
                }) {
        super(routes)
        this.blockNumber = blockNumber
        this.gasUseEstimateUSD = gasUseEstimateUSD
    }
}
