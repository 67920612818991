import React, { useEffect } from 'react';
import { Routes, Route, HashRouter } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Layout from "./Pages/Layout";
import '@coreui/coreui/dist/css/coreui.min.css'
import Pool from './Pages/Pool/Pool';
import Staking from './Pages/Staking/Staking';
import Swap from './Pages/Swaps/Swaps';
import { Provider } from 'react-redux';
import store from './redux/store';
import { Web3ReactProvider } from '@web3-react/core';
import { getLibrary } from './utils/web3React';

import 'sweetalert2/src/sweetalert2.scss'

// import {Toaster} from "react-hot-toast";
import useDirectConnect from "./hooks/useDirectConnect";
import {BlockNumberProvider} from "./hooks/useBlockNumber";
import {MulticallUpdater} from "./state/multicall";
import {usePollBlockNumber} from "./state/block/hooks";
// import { gsap } from "gsap";
import NftLiquidity from './Pages/NftLiquidity/NftLiquidity';
import { Buffer } from 'buffer';
import Lottery from './Pages/Lottery/Lottery';

// import Updater from "./state/multicall/updater";

function Updaters() {
  return <>
    <MulticallUpdater />
  </>
}

function AppRoutes() {

  useDirectConnect()
  usePollBlockNumber()

  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="/" element={<Layout />}>
        {/* <Route path="swap" element={<Swap />} /> */}
        {/* <Route path="nftliquidity" element={<NftLiquidity />} /> */}
        {/*<Route path="pool-info" element={<Pool />} />*/}
        {/* <Route path="pool" element={<Pool />}> */}
          {/* <Route path=":currencyIdA" />
          <Route path=":currencyIdA/:currencyIdB" />
          <Route path=":currencyIdA/:currencyIdB/:feeAmount" />
        </Route> */}
        {/* <Route path="staking" element={<Staking />} /> */}
        {/* <Route path="lottery" element={<Lottery />} /> */}
      </Route>
    </Routes>
  );
}

function App() {
  window.Buffer = Buffer;
  useEffect(() => {
    function handlePageLoad() {
      window.scrollTo(0, 0);
    }
    window.addEventListener('load', handlePageLoad);
    const timer = setTimeout(() => {
      document.body.classList.add('pageLoaded');
    }, 100);
    //5000
    const timer1 = setTimeout(() => {
      document.body.classList.add('loadDash');
    }, 100);
    // 1000


    return () => {
      // window.removeEventListener('load', handlePageLoad);
      clearTimeout(timer);
      clearTimeout(timer1);
    };
  }, []);
  return (
    <HashRouter>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Provider store={store}>
          <BlockNumberProvider>
            <Updaters />
            {/* <Toaster /> */}
            <AppRoutes />
          </BlockNumberProvider>
        </Provider>
      </Web3ReactProvider>
    </HashRouter>
  );
}

export default App;
