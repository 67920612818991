import { FeeAmount } from '@uniswap/v3-sdk'
import { useMemo } from 'react'

import { useAllCurrencyCombinations } from './useAllCurrencyCombinations'
import { PoolState, usePools } from './usePool'

export function useV3SwapPools(
    currencyIn,
    currencyOut
) {

    const allCurrencyCombinations = useAllCurrencyCombinations(currencyIn, currencyOut)

    const allCurrencyCombinationsWithAllFees = useMemo(
        () =>
            allCurrencyCombinations.reduce((list, [tokenA, tokenB]) => {
                return list.concat([
                        [tokenA, tokenB, FeeAmount.LOWEST],
                        [tokenA, tokenB, FeeAmount.LOW],
                        [tokenA, tokenB, FeeAmount.MEDIUM],
                        [tokenA, tokenB, FeeAmount.HIGH],
                    ])
            }, []),
        [allCurrencyCombinations]
    )

    const pools = usePools(allCurrencyCombinationsWithAllFees)

    return useMemo(() => {
        return {
            pools: pools
                .filter((tuple) => {
            return tuple[0] === PoolState.EXISTS && tuple[1] !== null
        })
    .map(([, pool]) => pool),
            loading: pools.some(([state]) => state === PoolState.LOADING),
    }
    }, [pools])
}
