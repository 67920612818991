import { useMemo } from 'react'
import { TradeState } from '../state/routing/types'
import { useClientSideV3Trade } from './useClientSideV3Trade'
import useDebounce from './useDebounce'

export function useBestTrade(
    tradeType,
    amountSpecified,
    otherCurrency
) {

    const [debouncedAmount, debouncedOtherCurrency] = useDebounce(
        useMemo(() => [amountSpecified, otherCurrency], [amountSpecified, otherCurrency]),
        200
    )

    // const routingAPITrade = useRoutingAPITrade(
    //     tradeType,
    //     autoRouterSupported && isWindowVisible ? debouncedAmount : undefined,
    //     debouncedOtherCurrency
    // )

    // const isLoading = routingAPITrade.state === TradeState.LOADING
    const useFallback = true

    // only use client side router if routing api trade failed or is not supported
    const bestV3Trade = useClientSideV3Trade(
        tradeType,
        useFallback ? debouncedAmount : undefined,
        useFallback ? debouncedOtherCurrency : undefined
    )

    // only return gas estimate from api if routing api trade is used
    return useMemo(
        () => ({
            ...(useFallback ? bestV3Trade : null),
            ...(false ? { state: TradeState.LOADING } : {}),
        }),
        [bestV3Trade, useFallback]
    )
}
